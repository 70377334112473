const urlPrefix = 'redirect-'

export const replaceStateParamWithRedirectUrl = (
  url: string,
  value: string
) => {
  const urlObj = new URL(url)

  urlObj.searchParams.set('state', urlPrefix + value)

  return urlObj.toString()
}

export const isStateParamARedirectUrl = (state: string) => {
  if (!state) {
    return false
  }

  return state.startsWith(urlPrefix)
}

export const extractRedirectUrlFromStateParam = (state: string) => {
  if (!state) {
    return null
  }

  return state.replace(urlPrefix, '')
}
