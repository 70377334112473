import { root } from './HighlightText.styles'
import { IHighlightText } from './HighlightText.types'

const HighlightText = ({
  children,
  color = 'grey20',
  fill = 'grey15',
  space = 0.75
}: IHighlightText) => (
  <div
    data-testid='highlighttext'
    css={root({
      color,
      fill,
      space
    })}
  >
    <div>{children}</div>
  </div>
)

export default HighlightText
