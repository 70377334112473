import SeoBoundary from 'lib/boundaries/SeoBoundary'
import PaymentsSideText from 'lib/layouts/BrandedLayout/PaymentsSideText'
import BaseDefaultLayout from 'lib/layouts/DefaultLayout'
import { IDefaultLayout as IDefaultLayoutLib } from 'lib/layouts/DefaultLayout/types'
import image from 'public/half-circle-grey.svg'

import DefaultSideBottom from './DefaultSideBottom'
import { content } from './styles'
import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'
import Logo from '@/ui/Logo'

interface IDefaultLayout
  extends Pick<
    IDefaultLayoutLib,
    | 'children'
    | 'headline'
    | 'headlineProps'
    | 'title'
    | 'width'
    | 'alignment'
    | 'center'
    | 'sideContent'
    | 'prevUrl'
    | 'nextUrl'
    | 'formId'
    | 'disabled'
    | 'styles'
    | 'loading'
    | 'loadingIcon'
    | 'ctaContent'
    | 'stepProps'
  > {
  useBackgroundImage?: boolean
  useSideBottom?: boolean
}
const DefaultLayout = ({
  alignment = 'center',
  center = true,
  children,
  formId,
  headline,
  headlineProps,
  nextUrl,
  prevUrl,
  stepProps,
  title,
  width,
  ctaContent,
  disabled,
  loading,
  loadingIcon,
  sideContent,
  useBackgroundImage,
  useSideBottom
}: IDefaultLayout) => {
  const { brandColor, company, invoice } = useCompanyAndInvoiceContext()
  const partnerName = company?.name || invoice?.partner?.name

  return (
    <SeoBoundary
      title={`${partnerName || 'Alternative'} Payments - ${
        title || headline || 'Manage payments and invoices'
      }`}
    >
      <BaseDefaultLayout
        styles={{ content }}
        backgroundImage={useBackgroundImage && image}
        brandColor={brandColor}
        logo={<Logo />}
        sideBottom={useSideBottom && <DefaultSideBottom />}
        sideContent={
          !sideContent ? (
            <PaymentsSideText
              brandColor={brandColor}
              partnerName={partnerName}
            />
          ) : (
            sideContent
          )
        }
        alignment={alignment}
        formId={formId}
        headline={headline}
        headlineProps={headlineProps}
        nextUrl={nextUrl}
        prevUrl={prevUrl}
        stepProps={stepProps}
        title={title}
        width={width}
        center={center}
        ctaContent={ctaContent}
        disabled={disabled}
        loading={loading}
        loadingIcon={loadingIcon}
        sideBackground='/ribbon2.png'
      >
        {children}
      </BaseDefaultLayout>
    </SeoBoundary>
  )
}

export default DefaultLayout
