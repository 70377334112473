import { Suspense } from 'react'

import Dots from 'lib/ui/Animations/Loading/Dots'
import Icon from 'lib/ui/Icon'

import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'

const DefaultSideBottom = () => {
  const { company } = useCompanyAndInvoiceContext()
  const logo = company?.paymentsPortalCustomization?.logoUrl

  return (
    <Suspense fallback={<Dots />}>
      {logo && !!company && (
        <div
          style={{
            marginLeft: '-16px',
            marginBottom: '-28px'
          }}
        >
          <Icon
            name='alternativePaymentsPoweredByLogo'
            height={7.5}
            width={18.4}
            viewBox='0 0 184 75'
          />
        </div>
      )}
    </Suspense>
  )
}
export default DefaultSideBottom
