import { ApolloQueryResult } from '@apollo/client'
import Cookies from 'cookies'
import {
  OAuth2UrlDocument,
  OAuth2UrlQuery,
  GetUserInfoDocument,
  GetUserInfoQuery,
  CustomerUserInfoDataFragment
} from 'graph/generated/payments/graphql-types'
import Alert from 'lib/ui/Alert'
import Text from 'lib/ui/Text'
import type {
  GetServerSideProps,
  GetServerSidePropsResult,
  NextPage
} from 'next'
import { AppCookie, getCookieExpires } from 'utils/cookies'

import DefaultLayout from '@/layouts/DefaultLayout'
import LoginButton from '@/ui/Button/LoginButton'
import { paymentsSSRClient } from '@/utils/apollo'

type PageProps = {
  errorMessage?: string
  users?: CustomerUserInfoDataFragment[]
}

const PaymentsLanding: NextPage = (props: PageProps) => {
  const { errorMessage } = props

  if (errorMessage) {
    return (
      <DefaultLayout
        title='Authentication error'
        headline='Authentication error'
      >
        <Alert variant='danger' showIcon margin={[0, 0, 2]}>
          {errorMessage}
        </Alert>
        <LoginButton />
      </DefaultLayout>
    )
  }

  // fallback UI with login button
  return (
    <DefaultLayout title='Login' headline='You are logged out'>
      <Text>Would you like to login?</Text>
      <LoginButton />
    </DefaultLayout>
  )
}

async function getUserInfo(id_token: string, host: string) {
  const { data: userInfoResponse }: ApolloQueryResult<GetUserInfoQuery> =
    await paymentsSSRClient.query({
      query: GetUserInfoDocument,
      context: {
        headers: {
          Authorization: `Bearer ${id_token}`,
          'X-Origin': process.env.NEXT_PUBLIC_PORTAL_DOMAIN || host
        }
      }
    })

  return userInfoResponse.GetUserInfo?.users || []
}

export const getServerSideProps: GetServerSideProps = async ({
  req,
  query,
  res
}): Promise<GetServerSidePropsResult<PageProps>> => {
  const customerId = query.customerId as string
  const host = req.headers.host

  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
    return { props: {} }
  }

  // Check if the request method is OPTIONS (preflight), don't run any logic if it is as this overrides team selection
  if (req.method === 'OPTIONS') {
    return { props: {} }
  }

  const cookies = new Cookies(req, res)
  const token = cookies.get(AppCookie.TokenCustomer)

  if (customerId) {
    cookies.set(AppCookie.CustomerId, customerId, {
      expires: getCookieExpires(AppCookie.CustomerId),
      httpOnly: false
    })
  }

  if (token) {
    try {
      // Get user info
      const users = await getUserInfo(token, host)

      // Check if the user already has an account
      if (users.length >= 1 && !customerId) {
        const customer = users[0].customer

        // User exists and has one or more accounts, redirect to dashboard after setting the customer id cookie
        cookies.set(AppCookie.CustomerId, customer?.id, {
          expires: getCookieExpires(AppCookie.CustomerId),
          httpOnly: false
        })

        return {
          redirect: {
            destination: '/dashboard',
            permanent: false
          }
        }
      }
    } catch (error) {
      // Log errors to Vercel
      // eslint-disable-next-line no-console
      console.error('Error checking token validity:', error)
    }
  }

  // If there's no token or the token is invalid, redirect to the authentication page
  try {
    const authUrlResponse: ApolloQueryResult<OAuth2UrlQuery> =
      await paymentsSSRClient.query({
        query: OAuth2UrlDocument,
        context: {
          headers: {
            'X-Origin': process.env.NEXT_PUBLIC_PORTAL_DOMAIN || host
          }
        }
      })

    const authUrl = authUrlResponse?.data?.OAuth2Url

    if (!authUrl) {
      throw new Error('No authentication URL found')
    }

    return {
      redirect: {
        destination: authUrl,
        permanent: false
      }
    }
  } catch (error) {
    // Log errors to Vercel
    // eslint-disable-next-line no-console
    console.error('Error fetching authentication URL:', error)
    return {
      props: {
        errorMessage: error.message
      }
    }
  }
}

export default PaymentsLanding
