import HighlightText from 'lib/ui/HighlightText'
import { ColorProps } from 'utils/types'

interface IPaymentsSideText {
  partnerName: string
  brandColor: ColorProps
}

const PaymentsSideText = ({ partnerName, brandColor }: IPaymentsSideText) => {
  if (partnerName)
    return (
      <HighlightText fill={brandColor?.hex} color='grey30'>
        <span data-testid='company-name'>{partnerName}</span> partners with
        Alternative Payments to provide a smooth payment experience.
      </HighlightText>
    )
  return null
}

export default PaymentsSideText
