import { createStyles } from 'utils/css'

import { IHighlightText } from './HighlightText.types'

export const root = ({
  color,
  fill,
  space
}: Pick<IHighlightText, 'color' | 'fill' | 'space'>) =>
  createStyles(({ colors, radii, spacing }) => ({
    color: colors[color] || color,
    borderLeft: `6px solid ${colors[fill] || fill}`,
    paddingLeft: spacing(space),
    borderRadius: radii.md
  }))
