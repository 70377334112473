import { useOAuth2UrlLazyQuery } from 'graph/generated/payments/graphql-types'
import { replaceStateParamWithRedirectUrl } from 'utils/ory'

import Button from '@/ui/Button'
/**
 * @returns a button that will get redirect link to auth app from hydra
 * so user will be able to login and be redirected back to payments app
 */

interface ILoginButton {
  redirectTo?: string
}

const LoginButton = ({ redirectTo }: ILoginButton) => {
  const [getOAuth2Url] = useOAuth2UrlLazyQuery()

  const handleLogin = async () => {
    const url = await getOAuth2Url()
    window.location.href = redirectTo
      ? replaceStateParamWithRedirectUrl(url?.data?.OAuth2Url, redirectTo)
      : url?.data?.OAuth2Url
  }
  return <Button onClick={handleLogin}>Login</Button>
}

export default LoginButton
